import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
    handleCreateFulfilled
    ,
    handleFetchSinglePending
    ,
    handleFetchSingleFulfilled
    ,
    handleFetchSingleFromListFulfilled
    ,
    handleFetchSingleRejected
    ,
    handleFetchListPending
    ,
    handleFetchListFulfilled
    ,
    handleFetchListRejected
    ,
    handleMutationPending
    ,
    handleMutationFulfilled
    ,
    handleMutationRejected
    ,
    handleDeletePending
    ,
    handleDeleteFulfilled
    ,
    handleDeleteRejected
    ,
    shouldFetch
    ,
    INITIAL_STATE
    ,
    handleInvalidateQuery
    ,
    handleInvalidateQueries
    ,
    handleAddSingleToList
    ,
    handleAddManyToList,
    handleFetchCourseUserByCourseIDUserId,
    handleFetchCourseUserByCourseIDUserIdRejected,
    handleFetchCourseUserByCourseIDUserIdFulfilled
} from '../../global/utils/storeUtils';


// First define all API calls for courseUser

// define and export the strings for the different specific courseUser endpoints once here because the idea of using strings in the component gives me hives.
// we'll catch for these strings on the server side and apply the correct permissions to the query.
// these are passed in to the courseUserService hooks at the component level as the endpoint argument.
// NOTE: If any of these are functions that require arguments, return null until all arguments are provided so the fetching hook will hold off on the fetch instead of calling an endpoint that will fail.
// export const myExampleEndpoint = 'example-endpoint';
// export const myEndpointWithArgs = (arg1, arg2) => {
//   if(!arg1 || !arg2) return null;
//   return `example-endpoint/${arg1}/${arg2}`;
// };

export const courseUsersByCourseEndpoint = courseId => !courseId ? null : `by-instructor-course/${courseId}`;
export const courseUserByStudentCourseEndpoint = courseId => !courseId ? null : `by-student-course/${courseId}`;

// TODO: custom create single endpoint
// export const createCourseUserInCourse = courseId => !courseId ? null : `${courseId}`; // POST



/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 *
 * In practice we won't dispatch these directly, they will be dispatched by courseUserService which has a nicer api built on hooks.
 */

// CREATE
export const sendCreateCourseUser = createAsyncThunk(
  'courseUser/sendCreate'
  , async (newCourseUser) => {
    const endpoint = `/api/course-users`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newCourseUser);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// create many
export const sendBulkUploadCourseUsers = createAsyncThunk(
  'courseUser/sendCreateMany'
  , async ({courseId, emails, sendEmails }) => {
    const endpoint = `/api/course-users/${courseId}`;
    const response = await apiUtils.callAPI(endpoint, 'POST', {emails: emails, sendEmails: sendEmails});
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
)

export const sendStudentRespondToInvite = createAsyncThunk(
  'courseUser/sendStudentRespondToInvite'
  , async (courseUserId, inviteStatus) => {
    console.log("STATUS TO SEND", inviteStatus)
    const endpoint = `/api/course-users/respond-to-invite/${courseUserId}`;
    const response = await apiUtils.callAPI(endpoint, 'POST', {inviteStatus: inviteStatus});
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleCourseUser = createAsyncThunk(
  'courseUser/fetchSingle'
  , async (id) => {
    const endpoint = `/api/course-users/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchCourseUserList = createAsyncThunk(
  'courseUser/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/course-users${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const fetchCourseUserByCourseIDUserId = createAsyncThunk(
    'courseUser/fetchCourseUserByCourseIDUserId',
    async (listArgs) => {
        const {courseId, userId} = listArgs;
        // The value we return becomes the `fulfilled` action payload
        const endpoint = `/api/course-users/${courseId}/${userId}`
        return await apiUtils.callAPI(endpoint, 'POST');
    }
);

// for each resource we can add as many endpoints as we want in this format and we only need two actions to handle them.
// this will hit the same endpoint as the list version, but the store will handle the returned array and access the single item in it.
export const fetchSingleCourseUserAtEndpoint = createAsyncThunk(
  'courseUser/fetchSingleWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/course-users${query}` // example: `/api/course-users/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchCourseUserListAtEndpoint = createAsyncThunk(
  'courseUser/fetchListWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/course-users${query}`; // example: `/api/course-users/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateCourseUser = createAsyncThunk(
  'courseUser/sendUpdate'
  , async ({_id, _course, ...updates }) => {
    const endpoint = `/api/course-users/${_course}/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteCourseUser = createAsyncThunk(
  'courseUser/sendDelete'
  , async (id) => {
    const endpoint = `/api/course-users/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the courseUserSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const courseUserSlice = createSlice({
  name: 'courseUser'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   *
   * Again, we will not dispatch these directly, they will be dispatched by courseUserService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addCourseUserToList: handleAddSingleToList
    , addCourseUsersToList: handleAddManyToList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   *
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateCourseUser.fulfilled, handleCreateFulfilled)
      .addCase(sendStudentRespondToInvite.fulfilled, handleCreateFulfilled)

      // READ
      .addCase(fetchSingleCourseUser.pending, handleFetchSinglePending)
      .addCase(fetchSingleCourseUser.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleCourseUser.rejected, handleFetchSingleRejected)
      .addCase(fetchCourseUserList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchCourseUserList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'courseUsers'))
      .addCase(fetchCourseUserList.rejected, handleFetchListRejected)

      // permission protected single fetches
      .addCase(fetchSingleCourseUserAtEndpoint.pending, handleFetchSinglePending)
      // these endpoints return named lists, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchSingleCourseUserAtEndpoint.fulfilled, (state, action) => handleFetchSingleFromListFulfilled(state, action, 'courseUsers'))
      .addCase(fetchSingleCourseUserAtEndpoint.rejected, handleFetchSingleRejected)
      // permission protected list fetches
      .addCase(fetchCourseUserListAtEndpoint.pending, handleFetchListPending)
      .addCase(fetchCourseUserListAtEndpoint.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'courseUsers'))
      .addCase(fetchCourseUserListAtEndpoint.rejected, handleFetchListRejected)

      // UPDATE
      .addCase(sendUpdateCourseUser.pending, handleMutationPending)
      .addCase(sendUpdateCourseUser.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateCourseUser.rejected, handleMutationRejected)
      // .addCase(sendUpdateCourseUser.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteCourseUser.pending, handleDeletePending)
      .addCase(sendDeleteCourseUser.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteCourseUser.rejected, handleDeleteRejected)
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addCourseUserToList, addCourseUsersToList } = courseUserSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// accepts an optional listFetch action so we can use it for other list fetches, defaults to fetchCourseUserList
export const fetchListIfNeeded = (queryKey, listFetch = fetchCourseUserList) => (dispatch, getState) => {
  const courseUserQuery = getState().courseUser.listQueries[queryKey];
  if(shouldFetch(courseUserQuery)) {
    // console.log('Fetching courseUser list', queryKey);
    dispatch(listFetch(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

// accepts an optional singleFetch action so we can use it for other single fetches, defaults to fetchSingleCourseUser
export const fetchSingleIfNeeded = (id, singleFetch = fetchSingleCourseUser) => (dispatch, getState) => {
  const courseUserQuery = getState().courseUser.singleQueries[id];
  if(shouldFetch(courseUserQuery)) {
    dispatch(singleFetch(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export const fetchSingleByCourseIdUserId = (data, singleFetch = fetchCourseUserByCourseIDUserId) => (dispatch, getState) => {
    const {courseId} = data;
    const courseUserQuery = getState().courseUser.singleQueries[courseId];
    if(shouldFetch(courseUserQuery)) {
        dispatch(singleFetch(data));
    } else {
        // console.log('No need to fetch, fresh query in cache');
    }
}

export default courseUserSlice.reducer;
