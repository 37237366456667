/**
 * This file dynamically compiles the module routes for the client and
 * redirects any route that doesn't match to a 'NotFound'/404 page
 *
 * Below we import all module routes and assign them top-level pathnames as the
 * kebab-case version of their resourceName. For example in resourceRoutes.js.jsx:
 * export { default as userWorkouts } ... would become a top-level Route with the
 * path="/user-workouts" and so on.
 */

// import primary libraries
import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import YTRoute from '../global/components/routing/YTRoute.jsx';

// import third-party libraries
import _ from 'lodash';

// import main client and admin routes from the modules
// import * as adminResourceRoutes from './adminResourceRoutes.js';
import * as adminResourceRoutes from './adminResourceRoutes.js';
import * as instructorResourceRoutes from './instructorResourceRoutes.js';
import * as studentResourceRoutes from './studentResourceRoutes.js';
import * as userResourceRoutes from './userResourceRoutes.js';

// // import custom components
import UserLogin from '../resources/user/views/UserLogin.jsx';
import UserRegister from '../resources/user/views/UserRegister.jsx';
import Landing from '../global/components/landing/Landing.jsx';
import AdminDashboard from '../resources/user/admin/views/AdminDashboard.jsx'; 
import InstructorDashboard from '../resources/user/instructor/views/InstructorDashboard.jsx'; 
import Dashboard from '../resources/user/views/Dashboard.jsx';
// lazy load the rest to reduce initial bundle size, most users will never need them
const Forbidden = lazy(() => import('../global/components/navigation/Forbidden.jsx'));
const NotFound = lazy(() => import('../global/components/navigation/NotFound.jsx'));

// import AdminRouter from '../global/admin/AdminRouter.jsx';

const routes =
  <Switch>
    <Route exact path="/" component={Landing} />
    <YTRoute exact path="/admin" login={true} admin={true} component={AdminDashboard}/>
    {/* <YTRoute exact path="/instructor" login={true} instructor={true} component={InstructorDashboard}/> */}
    <Redirect exact from="/instructor" to="/instructor/courses" />
    {/* <Route exact path="/learn" login={true} component={Dashboard}/> */}
    <Redirect exact from="/learn" to="/learn/courses"/>
    <Route exact path="/unauthorized" component={Forbidden} />
    {/* moved UserLogin here to the top so it can be loaded without loading the entire user router in the inital bundle */}
    <Route exact path="/user/login" component={UserLogin} />
    <Route exact path="/user/register" component={UserRegister}/>
    {Object.keys(userResourceRoutes).map((resourceName, i) =>
      <Route
        component={userResourceRoutes[resourceName]}
        key={`userResourceRoute-${i}`}
        path={`/${_.kebabCase(resourceName)}`}
      />
    )}
    {Object.keys(studentResourceRoutes).map((resourceName, i) =>
      <Route
        component={studentResourceRoutes[resourceName]}
        key={`studentResourceRoute-${i}`}
        path={`/learn/${_.kebabCase(resourceName)}`}
      />
    )}
    {Object.keys(adminResourceRoutes).map((resourceName, i) =>
      <Route
        component={adminResourceRoutes[resourceName]}
        key={`adminResourceRoute-${i}`}
        path={`/admin/${_.kebabCase(resourceName)}`}
      />
    )}
    {Object.keys(instructorResourceRoutes).map((resourceName, i) =>
      <Route
        component={instructorResourceRoutes[resourceName]}
        key={`instructorResourceRoutes-${i}`}
        path={`/instructor/${_.kebabCase(resourceName)}`}
      />
    )}
    <Route component={NotFound} />
  </Switch>
;

export default routes;
