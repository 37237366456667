import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const sendRegister = createAsyncThunk(
  'auth/sendRegister'
  , async (userInfo) => {
    const response = await apiUtils.callAPI('/api/users/register', 'POST', userInfo);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendLogin = createAsyncThunk(
  'auth/sendLogin'
  , async (userInfo) => {
    const response = await apiUtils.callAPI('/api/users/login', 'POST', userInfo);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendLogout = createAsyncThunk(
  'auth/sendLogout'
  , async () => {
    const response = await apiUtils.callAPI('/api/users/logout', 'POST');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendGetLoggedIn = createAsyncThunk(
  'auth/sendGetLoggedIn'
  , async () => {
    const response = await apiUtils.callAPI('/api/users/get-logged-in', 'POST');
    return response
  }
);

export const sendRequestPasswordReset = createAsyncThunk(
  'auth/sendRequestPasswordReset'
  , async (email) => {
    const response = await apiUtils.callAPI('/api/users/request-password-reset', 'POST', { email });
    return response
  }
);

export const sendCheckResetToken = createAsyncThunk(
  'auth/sendCheckResetToken'
  , async (hex) => {
    // Adding noreferrer to the headers on this call to avoid referrer leakage: https://portswigger.net/kb/issues/00500400_cross-domain-referer-leakage
    const response = await apiUtils.callAPI('/api/users/check-reset-token', 'POST', { hex }, {
    'Accept': 'application/json', 'Content-Type': 'application/json', 'Referrer-Policy': 'no-referrer'
  });
    return response
  }
);

export const sendResetPassword = createAsyncThunk(
  'auth/sendResetPassword'
  , async ({hex, password}) => {
    // Adding noreferrer to the headers on this call to avoid referrer leakage: https://portswigger.net/kb/issues/00500400_cross-domain-referer-leakage
    const response = await apiUtils.callAPI('/api/users/reset-password', 'POST', { hex, password }, {
    'Accept': 'application/json', 'Content-Type': 'application/json', 'Referrer-Policy': 'no-referrer'
  });
    return response
  }
);

export const sendUpdatePassword = createAsyncThunk(
  'auth/sendUpdatePassword'
  , async ({ oldPass, newPass, confirmPass }) => {
    const response = await apiUtils.callAPI('/api/users/password', 'POST', { oldPass, newPass, confirmPass });
    return response
  }
);

export const authStore = createSlice({
  name: 'auth'
  , initialState: {
    loggedInUser: null
    , status: 'idle'
    , error: null
  }
  // The `reducers` field lets us define reducers and generate associated actions
  , reducers: {
    // none needed here
  }
  // The `extraReducers` field lets the store handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other stores.
  , extraReducers: (builder) => {
    builder
      .addCase(sendRegister.pending, (state) => {
        state.status = 'pending';
        state.error = null
      })
      .addCase(sendRegister.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUser = action.payload;
      })
      .addCase(sendRegister.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message
      })
      .addCase(sendLogin.pending, (state) => {
        state.status = 'pending';
        state.error = null
      })
      .addCase(sendLogin.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUser = action.payload;
      })
      .addCase(sendLogin.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      })
      .addCase(sendLogout.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(sendLogout.fulfilled, (state) => {
        state.status = 'idle';
        state.loggedInUser = null;
      })
      .addCase(sendLogout.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      })
      .addCase(sendGetLoggedIn.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(sendGetLoggedIn.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.loggedInUser = action.payload;
      })
      .addCase(sendGetLoggedIn.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      })
      .addCase(sendRequestPasswordReset.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(sendRequestPasswordReset.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUser = null;
      })
      .addCase(sendRequestPasswordReset.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      })
      .addCase(sendResetPassword.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(sendResetPassword.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUser = null;
      })
      .addCase(sendResetPassword.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      })
      .addCase(sendUpdatePassword.pending, (state) => {
        // do nothing? this should probably live in a separate store along with the reset password stuff since it doesn't deal with loggedInUser state.
      })
      .addCase(sendUpdatePassword.fulfilled, (state, action) => {
        // do nothing? this should probably live in a separate store along with the reset password stuff since it doesn't deal with loggedInUser state.
      })
      .addCase(sendUpdatePassword.rejected, (state, action) => {
        // do nothing? this should probably live in a separate store along with the reset password stuff since it doesn't deal with loggedInUser state.
      })
  }
});





// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
/**
 *
 * @returns logged in user object
 */
export const selectLoggedInUser = ({ auth }) => {
  return auth.loggedInUser;
}

/**
 *
 * @returns logged in user fetch status
 */
export const selectAuthStatus = ({ auth }) => {
  return auth.status
}

export default authStore.reducer;

