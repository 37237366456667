import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
  handleCreateFulfilled
  , handleFetchSinglePending
  , handleFetchSingleFulfilled
  , handleFetchSingleFromListFulfilled
  , handleFetchSingleRejected
  , handleFetchListPending
  , handleFetchListFulfilled
  , handleFetchListRejected
  , handleMutationPending
  , handleMutationFulfilled
  , handleMutationRejected
  , handleDeletePending
  , handleDeleteFulfilled
  , handleDeleteRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
  , handleAddSingleToList
  , handleAddManyToList
} from '../../global/utils/storeUtils';


// First define all API calls for question

// define and export the strings for the different specific question endpoints once here because the idea of using strings in the component gives me hives.
// we'll catch for these strings on the server side and apply the correct permissions to the query.
// these are passed in to the questionService hooks at the component level as the endpoint argument.
// export const myExampleEndpoint = 'logged-in';

/**
 * This endpoint is open to admin and instructor users. For instructors it will only return published questions.
 * @param {string} id 
 * @returns {string} the endpoint for previewing a question 
 */
export const previewQuestionEndpoint = (id) => {
  if(!id) return null;
  return `preview/${id}`
}

export const instructorSingleQuestionEndpoint = (id) => {
  if(!id) return null;
  return `by-instructor/${id}`
}

/**
 * This endpoint is open to admin and instructor users. For instructors it will only return published questions.
 */
export const publishedQuestionsEndpoint = `published`; 

/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 * 
 * In practice we won't dispatch these directly, they will be dispatched by questionService which has a nicer api built on hooks.
 */

// CREATE
export const sendCreateQuestion = createAsyncThunk(
  'question/sendCreate'
  , async (newQuestion) => {
    const endpoint = `/api/questions`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newQuestion);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendCopyQuestion = createAsyncThunk(
  'question/sendCopy'
  , async (questionId) => {
    const endpoint = `/api/questions/${questionId}/copy`;
    const response = await apiUtils.callAPI(endpoint, 'POST');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleQuestion = createAsyncThunk(
  'question/fetchSingle'
  , async (id) => {
    const endpoint = `/api/questions/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchQuestionList = createAsyncThunk(
  'question/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/questions${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// for each resource we can add as many endpoints as we want in this format and we only need two actions to handle them.
// this will hit the same endpoint as the list version, but the store will handle the returned array and access the single item in it.
export const fetchSingleQuestionAtEndpoint = createAsyncThunk(
  'question/fetchSingleWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/questions${query}` // example: `/api/questions/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchQuestionListAtEndpoint = createAsyncThunk(
  'question/fetchListWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/questions${query}`; // example: `/api/questions/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateQuestion = createAsyncThunk(
  'question/sendUpdate'
  , async ({ _id, ...updates }) => {
    const endpoint = `/api/questions/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteQuestion = createAsyncThunk(
  'question/sendDelete'
  , async (id) => {
    const endpoint = `/api/questions/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the questionSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const questionSlice = createSlice({
  name: 'question'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   * 
   * Again, we will not dispatch these directly, they will be dispatched by questionService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addQuestionToList: handleAddSingleToList
    , addQuestionsToList: handleAddManyToList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   * 
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateQuestion.fulfilled, handleCreateFulfilled)
      .addCase(sendCopyQuestion.fulfilled, handleCreateFulfilled)

      // READ
      .addCase(fetchSingleQuestion.pending, handleFetchSinglePending)
      .addCase(fetchSingleQuestion.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleQuestion.rejected, handleFetchSingleRejected)
      .addCase(fetchQuestionList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchQuestionList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'questions'))
      .addCase(fetchQuestionList.rejected, handleFetchListRejected)

      // permission protected single fetches
      .addCase(fetchSingleQuestionAtEndpoint.pending, handleFetchSinglePending)
      // these endpoints return named lists, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchSingleQuestionAtEndpoint.fulfilled, (state, action) => handleFetchSingleFromListFulfilled(state, action, 'questions'))
      .addCase(fetchSingleQuestionAtEndpoint.rejected, handleFetchSingleRejected)
      // permission protected list fetches
      .addCase(fetchQuestionListAtEndpoint.pending, handleFetchListPending)
      .addCase(fetchQuestionListAtEndpoint.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'questions'))
      .addCase(fetchQuestionListAtEndpoint.rejected, handleFetchListRejected)

      // UPDATE
      .addCase(sendUpdateQuestion.pending, handleMutationPending)
      .addCase(sendUpdateQuestion.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateQuestion.rejected, handleMutationRejected)
      // .addCase(sendUpdateQuestion.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteQuestion.pending, handleDeletePending)
      .addCase(sendDeleteQuestion.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteQuestion.rejected, handleDeleteRejected)
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addQuestionToList, addQuestionsToList } = questionSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// accepts an optional listFetch action so we can use it for other list fetches, defaults to fetchQuestionList
export const fetchListIfNeeded = (queryKey, listFetch = fetchQuestionList) => (dispatch, getState) => {
  const questionQuery = getState().question.listQueries[queryKey];
  if(shouldFetch(questionQuery)) {
    // console.log('Fetching question list', queryKey);
    dispatch(listFetch(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

// accepts an optional singleFetch action so we can use it for other single fetches, defaults to fetchSingleQuestion
export const fetchSingleIfNeeded = (id, singleFetch = fetchSingleQuestion) => (dispatch, getState) => {
  const questionQuery = getState().question.singleQueries[id];
  if(shouldFetch(questionQuery)) {
    dispatch(singleFetch(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export default questionSlice.reducer;
