import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
  handleCreateFulfilled
  , handleFetchSinglePending
  , handleFetchSingleFulfilled
  , handleFetchSingleFromListFulfilled
  , handleFetchSingleRejected
  , handleFetchListPending
  , handleFetchListFulfilled
  , handleFetchListRejected
  , handleMutationPending
  , handleMutationFulfilled
  , handleMutationRejected
  , handleDeletePending
  , handleDeleteFulfilled
  , handleDeleteRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
  , handleAddSingleToList
  , handleAddManyToList
} from '../../global/utils/storeUtils';


// First define all API calls for course

// define and export the strings for the different specific course endpoints once here because the idea of using strings in the component gives me hives.
// we'll catch for these strings on the server side and apply the correct permissions to the query.
// these are passed in to the courseService hooks at the component level as the endpoint argument.
// NOTE: If any of these are functions that require arguments, return null until all arguments are provided so the fetching hook will hold off on the fetch instead of calling an endpoint that will fail.
// export const myExampleEndpoint = 'example-endpoint';
// export const myEndpointWithArgs = (arg1, arg2) => {
//   if(!arg1 || !arg2) return null;
//   return `example-endpoint/${arg1}/${arg2}`;
// };

// names?
export const loggedInInstructorCoursesEndpoint = `logged-in-instructor`
export const loggedInStudentCoursesEndpoint = `logged-in-student`
export const loggedInStudentCourseInvitesEndpoint = `logged-in-invites`

/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 * 
 * In practice we won't dispatch these directly, they will be dispatched by courseService which has a nicer api built on hooks.
 */

// CREATE
export const sendCreateCourse = createAsyncThunk(
  'course/sendCreate'
  , async (newCourse) => {
    const endpoint = `/api/courses`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newCourse);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleCourse = createAsyncThunk(
  'course/fetchSingle'
  , async (id) => {
    const endpoint = `/api/courses/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchCourseList = createAsyncThunk(
  'course/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/courses${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// for each resource we can add as many endpoints as we want in this format and we only need two actions to handle them.
// this will hit the same endpoint as the list version, but the store will handle the returned array and access the single item in it.
export const fetchSingleCourseAtEndpoint = createAsyncThunk(
  'course/fetchSingleWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/courses${query}` // example: `/api/courses/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchCourseListAtEndpoint = createAsyncThunk(
  'course/fetchListWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/courses${query}`; // example: `/api/courses/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateCourse = createAsyncThunk(
  'course/sendUpdate'
  , async ({ _id, ...updates }) => {
    const endpoint = `/api/courses/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteCourse = createAsyncThunk(
  'course/sendDelete'
  , async (id) => {
    const endpoint = `/api/courses/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the courseSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const courseSlice = createSlice({
  name: 'course'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   * 
   * Again, we will not dispatch these directly, they will be dispatched by courseService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addCourseToList: handleAddSingleToList
    , addCoursesToList: handleAddManyToList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   * 
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateCourse.fulfilled, handleCreateFulfilled)

      // READ
      .addCase(fetchSingleCourse.pending, handleFetchSinglePending)
      .addCase(fetchSingleCourse.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleCourse.rejected, handleFetchSingleRejected)
      .addCase(fetchCourseList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchCourseList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'courses'))
      .addCase(fetchCourseList.rejected, handleFetchListRejected)

      // permission protected single fetches
      .addCase(fetchSingleCourseAtEndpoint.pending, handleFetchSinglePending)
      // these endpoints return named lists, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchSingleCourseAtEndpoint.fulfilled, (state, action) => handleFetchSingleFromListFulfilled(state, action, 'courses'))
      .addCase(fetchSingleCourseAtEndpoint.rejected, handleFetchSingleRejected)
      // permission protected list fetches
      .addCase(fetchCourseListAtEndpoint.pending, handleFetchListPending)
      .addCase(fetchCourseListAtEndpoint.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'courses'))
      .addCase(fetchCourseListAtEndpoint.rejected, handleFetchListRejected)

      // UPDATE
      .addCase(sendUpdateCourse.pending, handleMutationPending)
      .addCase(sendUpdateCourse.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateCourse.rejected, handleMutationRejected)
      // .addCase(sendUpdateCourse.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteCourse.pending, handleDeletePending)
      .addCase(sendDeleteCourse.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteCourse.rejected, handleDeleteRejected)
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addCourseToList, addCoursesToList } = courseSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// accepts an optional listFetch action so we can use it for other list fetches, defaults to fetchCourseList
export const fetchListIfNeeded = (queryKey, listFetch = fetchCourseList) => (dispatch, getState) => {
  const courseQuery = getState().course.listQueries[queryKey];
  if(shouldFetch(courseQuery)) {
    // console.log('Fetching course list', queryKey);
    dispatch(listFetch(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

// accepts an optional singleFetch action so we can use it for other single fetches, defaults to fetchSingleCourse
export const fetchSingleIfNeeded = (id, singleFetch = fetchSingleCourse) => (dispatch, getState) => {
  const courseQuery = getState().course.singleQueries[id];
  if(shouldFetch(courseQuery)) {
    dispatch(singleFetch(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export default courseSlice.reducer;
