import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
  handleCreateFulfilled
  , handleFetchSinglePending
  , handleFetchSingleFulfilled
  , handleFetchSingleFromListFulfilled
  , handleFetchSingleRejected
  , handleFetchListPending
  , handleFetchListFulfilled
  , handleFetchListRejected
  , handleMutationPending
  , handleMutationFulfilled
  , handleMutationRejected
  , handleDeletePending
  , handleDeleteFulfilled
  , handleDeleteRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
  , handleAddSingleToList
  , handleAddManyToList
  , selectListItems
} from '../../global/utils/storeUtils';


// First define all API calls for referent

// define and export the strings for the different specific referent endpoints once here because the idea of using strings in the component gives me hives.
// we'll catch for these strings on the server side and apply the correct permissions to the query.
// these are passed in to the referentService hooks at the component level as the endpoint argument.
// export const myExampleEndpoint = 'logged-in';
/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 * 
 * In practice we won't dispatch these directly, they will be dispatched by referentService which has a nicer api built on hooks.
 */

// CREATE
export const sendCreateReferent = createAsyncThunk(
  'referent/sendCreate'
  , async (newReferent) => {
    const endpoint = `/api/referents`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newReferent);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleReferent = createAsyncThunk(
  'referent/fetchSingle'
  , async (id) => {
    const endpoint = `/api/referents/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchReferentList = createAsyncThunk(
  'referent/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/referents${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// for each resource we can add as many endpoints as we want in this format and we only need two actions to handle them.
// this will hit the same endpoint as the list version, but the store will handle the returned array and access the single item in it.
export const fetchSingleReferentAtEndpoint = createAsyncThunk(
  'referent/fetchSingleWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/referents${query}` // example: `/api/referents/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchReferentListAtEndpoint = createAsyncThunk(
  'referent/fetchListWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/referents${query}`; // example: `/api/referents/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateReferent = createAsyncThunk(
  'referent/sendUpdate'
  , async ({ _id, ...updates }) => {
    const endpoint = `/api/referents/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteReferent = createAsyncThunk(
  'referent/sendDelete'
  , async (id) => {
    const endpoint = `/api/referents/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the referentSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const referentSlice = createSlice({
  name: 'referent'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   * 
   * Again, we will not dispatch these directly, they will be dispatched by referentService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addReferentToList: handleAddSingleToList
    , addReferentsToList: handleAddManyToList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   * 
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateReferent.fulfilled, handleCreateFulfilled)

      // READ
      .addCase(fetchSingleReferent.pending, handleFetchSinglePending)
      .addCase(fetchSingleReferent.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleReferent.rejected, handleFetchSingleRejected)
      .addCase(fetchReferentList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchReferentList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'referents'))
      .addCase(fetchReferentList.rejected, handleFetchListRejected)

      // permission protected single fetches
      .addCase(fetchSingleReferentAtEndpoint.pending, handleFetchSinglePending)
      // these endpoints return named lists, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchSingleReferentAtEndpoint.fulfilled, (state, action) => handleFetchSingleFromListFulfilled(state, action, 'referents'))
      .addCase(fetchSingleReferentAtEndpoint.rejected, handleFetchSingleRejected)
      // permission protected list fetches
      .addCase(fetchReferentListAtEndpoint.pending, handleFetchListPending)
      .addCase(fetchReferentListAtEndpoint.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'referents'))
      .addCase(fetchReferentListAtEndpoint.rejected, handleFetchListRejected)

      // UPDATE
      .addCase(sendUpdateReferent.pending, handleMutationPending)
      .addCase(sendUpdateReferent.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateReferent.rejected, handleMutationRejected)
      // .addCase(sendUpdateReferent.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteReferent.pending, handleDeletePending)
      .addCase(sendDeleteReferent.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteReferent.rejected, handleDeleteRejected)
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addReferentToList, addReferentsToList } = referentSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// accepts an optional listFetch action so we can use it for other list fetches, defaults to fetchReferentList
export const fetchListIfNeeded = (queryKey, listFetch = fetchReferentList) => (dispatch, getState) => {
  const referentStore = getState().referent;
  const referentQuery = referentStore.listQueries[queryKey];
  if(shouldFetch(referentQuery)) {
    // console.log('Fetching referent list', queryKey);
    return dispatch(listFetch(queryKey));
  } else {
    // return a resolved promise with the existing state to match signature of fetchReferentList so we can get a consistent return type for use in components
    const referents = selectListItems(referentStore, queryKey);
    return Promise.resolve({
      payload: {
        referents: referents || []
        , totalPages: referentQuery.totalPages
        , totalCount: referentQuery.totalCount
      }
      , error: null
    });
  }
};

// accepts an optional singleFetch action so we can use it for other single fetches, defaults to fetchSingleReferent
export const fetchSingleIfNeeded = (id, singleFetch = fetchSingleReferent) => (dispatch, getState) => {
  const referentQuery = getState().referent.singleQueries[id];
  if(shouldFetch(referentQuery)) {
    dispatch(singleFetch(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export default referentSlice.reducer;
