// import primary libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

// import form components
import { EmailInput, PasswordInput } from '../../../global/components/forms'

const UserLoginForm = ({
  handleChange
  , handleSubmit
  , user
  , redirectPath
}) => {
  const location = useLocation();
  let point = '';
    const loc_arr = location?.search ? location.search.split('/') : [];
    if (loc_arr?.length) {
        point = loc_arr[loc_arr.length - 2];
    }

    const type = point === 'instructor' ? 'Instructor' : point === 'learn' ? 'Student' : '';
    const sub_text = type === 'Student' ? 'Assessment Platform' : type === 'Instructor' ? 'Assessment Creator' : '';

  return (
      <div className="border border-solid bg-white shadow-sm rounded-sm mx-auto max-w-lg p-0 mt-16">
          <div className="min-h-[40px] bg-indigo-300 flex flex-row gap-2 pl-4 pr-4 pt-2 pb-2">
              <div className="min-h-full content-center text-amber-300">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor"
                       className="feather feather-book-open">
                      <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
                      <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
                  </svg>
              </div>
              <div className="min-h-[40px] flex flex-row gap-2 content-center">
                  <h2 className="content-center"><span className="font-bold">PRACTICE</span> QUEST</h2>
                  {type && <div className="min-h-full min-w-[4px] bg-amber-300"></div>}
              </div>
              <div className="flex flex-col text-blue-600">
                  <p className="text-blue-800">{type}</p>
                  <p className="text-blue-800">{sub_text}</p>
              </div>
          </div>
          <div className="p-4">
              <form name="userForm" onSubmit={handleSubmit}>
                  <h1 className="pb-4"> Sign In </h1>
                  <p className="pb-2">Email Address</p>
                  <EmailInput
                      name="username"
                      // label="Email Address"
                      value={user.username}
                      change={handleChange}
                      required={true}
                  />
                  <p className="pb-2">Password</p>
                  <PasswordInput
                      name="password"
                      // label="Password"
                      value={user.password}
                      change={handleChange}
                      required={true}
                  />
                  <div className="flex justify-end">
                      <Link
                          className="text-xs italic text-orange-500 mb-2"
                          to={{
                              pathname: "/user/forgot-password"
                              , state: location.state
                          }}
                      >
                          Forgot Password?
                      </Link>
                  </div>
                  <div className="py-2">
                      <button className="btn w-full" type="submit">Sign in</button>
                  </div>
              </form>

              <div className="mb-4">
                  <p className="pt-2 text-sm mb-2 text-stone-500">Need an account?</p>
                  {redirectPath ?
                      <Link
                          className="inline-block text-center btn-second w-full"
                          to={`/user/register/${redirectPath}`}
                      >
                          Register
                      </Link>
                      :
                      <Link
                          className="inline-block text-center btn-second w-full"
                          to={{
                              pathname: "/user/register"
                              , state: location.state
                          }}
                      >
                          Register
                      </Link>
                  }
              </div>
          </div>
      </div>
  )
}

UserLoginForm.propTypes = {
    handleChange: PropTypes.func.isRequired
    , handleSubmit: PropTypes.func.isRequired
    , user: PropTypes.object.isRequired
}

export default UserLoginForm;
