import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
  handleCreateFulfilled
  , handleFetchSinglePending
  , handleFetchSingleFulfilled
  , handleFetchSingleFromListFulfilled
  , handleFetchSingleRejected
  , handleFetchListPending
  , handleFetchListFulfilled
  , handleFetchListRejected
  , handleMutationPending
  , handleMutationFulfilled
  , handleMutationRejected
  , handleDeletePending
  , handleDeleteFulfilled
  , handleDeleteRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
  , handleAddSingleToList
  , handleAddManyToList
  , handleRemoveManyFromList
} from '../../global/utils/storeUtils';

import { sendGetNextUserQuestion, sendSubmitUserQuestionAnswers } from '../userAssessment/userAssessmentStore';


// First define all API calls for userQuestion

// define and export the strings for the different specific userQuestion endpoints once here because the idea of using strings in the component gives me hives.
// we'll catch for these strings on the server side and apply the correct permissions to the query.
// these are passed in to the userQuestionService hooks at the component level as the endpoint argument.
// NOTE: If any of these are functions that require arguments, return null until all arguments are provided so the fetching hook will hold off on the fetch instead of calling an endpoint that will fail.
// export const myExampleEndpoint = 'example-endpoint';
// export const myEndpointWithArgs = (arg1, arg2) => {
//   if(!arg1 || !arg2) return null;
//   return `example-endpoint/${arg1}/${arg2}`;
// };
export const userQuestionsByInstructorAssessmentEndpoint = assessmentId => !assessmentId ? null : `by-instructor-assessment/${assessmentId}`;
/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 * 
 * In practice we won't dispatch these directly, they will be dispatched by userQuestionService which has a nicer api built on hooks.
 */



// CREATE
export const sendCreateUserQuestion = createAsyncThunk(
  'userQuestion/sendCreate'
  , async (newUserQuestion) => {
    const endpoint = `/api/user-questions`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newUserQuestion);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleUserQuestion = createAsyncThunk(
  'userQuestion/fetchSingle'
  , async (id) => {
    const endpoint = `/api/user-questions/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchUserQuestionList = createAsyncThunk(
  'userQuestion/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/user-questions${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// for each resource we can add as many endpoints as we want in this format and we only need two actions to handle them.
// this will hit the same endpoint as the list version, but the store will handle the returned array and access the single item in it.
export const fetchSingleUserQuestionAtEndpoint = createAsyncThunk(
  'userQuestion/fetchSingleWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/user-questions${query}` // example: `/api/user-questions/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchUserQuestionListAtEndpoint = createAsyncThunk(
  'userQuestion/fetchListWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/user-questions${query}`; // example: `/api/user-questions/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateUserQuestion = createAsyncThunk(
  'userQuestion/sendUpdate'
  , async ({ _id, ...updates }) => {
    const endpoint = `/api/user-questions/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteUserQuestion = createAsyncThunk(
  'userQuestion/sendDelete'
  , async (id) => {
    const endpoint = `/api/user-questions/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the userQuestionSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const userQuestionSlice = createSlice({
  name: 'userQuestion'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   * 
   * Again, we will not dispatch these directly, they will be dispatched by userQuestionService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addUserQuestionToList: handleAddSingleToList
    , addUserQuestionsToList: handleAddManyToList
    , removeUserQuestionsFromList: handleRemoveManyFromList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   * 
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateUserQuestion.fulfilled, handleCreateFulfilled)

      // this is actually a `userAssessment` action that we return both the userAssessment and the userQuestion from, caught in both reducers
      .addCase(sendGetNextUserQuestion.fulfilled, (state, action) => {
        const { userQuestion } = action.payload;
        if(!userQuestion) return;
        const newAction = {
          ...action
          , payload: userQuestion
        }
        handleMutationFulfilled(state, newAction);
      })
      .addCase(sendSubmitUserQuestionAnswers.fulfilled, (state, action) => {
        const userQuestion = action.payload;
        if(!userQuestion) return;
        const newAction = {
          ...action
          , payload: userQuestion
        }
        handleMutationFulfilled(state, newAction);
      })

      // READ
      .addCase(fetchSingleUserQuestion.pending, handleFetchSinglePending)
      .addCase(fetchSingleUserQuestion.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleUserQuestion.rejected, handleFetchSingleRejected)
      .addCase(fetchUserQuestionList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchUserQuestionList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'userQuestions'))
      .addCase(fetchUserQuestionList.rejected, handleFetchListRejected)

      // permission protected single fetches
      .addCase(fetchSingleUserQuestionAtEndpoint.pending, handleFetchSinglePending)
      // these endpoints return named lists, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchSingleUserQuestionAtEndpoint.fulfilled, (state, action) => handleFetchSingleFromListFulfilled(state, action, 'userQuestions'))
      .addCase(fetchSingleUserQuestionAtEndpoint.rejected, handleFetchSingleRejected)
      // permission protected list fetches
      .addCase(fetchUserQuestionListAtEndpoint.pending, handleFetchListPending)
      .addCase(fetchUserQuestionListAtEndpoint.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'userQuestions'))
      .addCase(fetchUserQuestionListAtEndpoint.rejected, handleFetchListRejected)

      // UPDATE
      .addCase(sendUpdateUserQuestion.pending, handleMutationPending)
      .addCase(sendUpdateUserQuestion.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateUserQuestion.rejected, handleMutationRejected)
      // .addCase(sendUpdateUserQuestion.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteUserQuestion.pending, handleDeletePending)
      .addCase(sendDeleteUserQuestion.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteUserQuestion.rejected, handleDeleteRejected)
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addUserQuestionToList, addUserQuestionsToList, removeUserQuestionsFromList } = userQuestionSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// accepts an optional listFetch action so we can use it for other list fetches, defaults to fetchUserQuestionList
export const fetchListIfNeeded = (queryKey, listFetch = fetchUserQuestionList) => (dispatch, getState) => {
  const userQuestionQuery = getState().userQuestion.listQueries[queryKey];
  if(shouldFetch(userQuestionQuery)) {
    // console.log('Fetching userQuestion list', queryKey);
    dispatch(listFetch(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

// accepts an optional singleFetch action so we can use it for other single fetches, defaults to fetchSingleUserQuestion
export const fetchSingleIfNeeded = (id, singleFetch = fetchSingleUserQuestion) => (dispatch, getState) => {
  const userQuestionQuery = getState().userQuestion.singleQueries[id];
  if(shouldFetch(userQuestionQuery)) {
    dispatch(singleFetch(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export default userQuestionSlice.reducer;
