/**
 * Helper component for rendering basic text inputs
 */

// import primary libraries
import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({
  autoFocus,
  change,
  disabled,
  helpText,
  label,
  name,
  placeholder,
  required,
  value,
  border = `border rounded border-stone-400`,
    error,
  ...inputProps
}) => {
  return (
    <div className={`relative z-0 w-full mb-4 text-left m-1`}>
      {label ? (
        <label
          htmlFor={name}
          className="px-2 pt-1 text-xs absolute top-0 text-gray-500 bg-transparent z-10"
        >
          {label} <sup className="text-red-500">{required ? '*' : null}</sup>
        </label>
        )
        :
        null
      }
      <input
        autoComplete='off'
        autoFocus={autoFocus}
        // className={`px-2 text-base ${label ? 'pt-5 pb-1' : 'pt-2 pb-2'} block w-full mt-0 border rounded border-stone-400`}
        className={`px-2 text-base ${label ? 'pt-5 pb-1' : 'pt-2 pb-2'} block w-full mt-0 ${border}`}
        disabled={disabled}
        name={name}
        onChange={change}
        placeholder={placeholder}
        required={required}
        type="text"
        value={value}
        {...inputProps}
      />
      {helpText && <small className="text-xs text-gray-500"><em>{helpText}</em></small>}
      {error && <div className="text-xs text-red-800">{error}</div>}
    </div>
  )
}

TextInput.propTypes = {
  change: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  helpText: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  error: PropTypes.string
}

TextInput.defaultProps = {
  disabled: false,
  helpText: null,
  label: '',
  placeholder: '',
  required: false,
  error: '',
}

export default TextInput;
