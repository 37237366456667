/**
 * Helper form component for rendering new password inputs.
 *
 * This loads two password inputs and validates them within the component prior
 * to passing the valid password back up through props.
 */

// import primary libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import PasswordInput from './PasswordInput';
// password validation regex

// must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 8 characters long
const strengthCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$/;
// inputs of type password take a regex pattern to validate against but it can't have delimiters
// this will give us better messages in the form like 'please match the requested format'
const passwordInputPattern = strengthCheck.toString().slice(1, -1);

const NewPasswordInput = ({
  change
  , disabled
  , helpText
  , name
  , value
  , ...inputProps
}) => {
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');

  const handleChange = (e) => {
    const { name: passwordName, value } = e.target;
    if (passwordName === 'pass1') {
      setPass1(value);
      if (!value) setPass2(''); // if pass1 is cleared, clear pass2
    } else if (passwordName === 'pass2') {
      setPass2(value);
    }
  }

  // when pass1 or pass2 changes, check if they match, if so pass the value up to the parent
  useEffect(() => {
    if(value && value === pass1 && value === pass2) {
      // if the value is already set and it matches both passwords, don't do anything
      return;
    } else if(pass1 === pass2 && strengthCheck.test(pass1)) {
      // send the valid password as an event to the parent component
      change({ target: { name, value: pass1 } });
    } else if(value) {
      // value is stale, clear it
      change({ target: { name, value: '' } });
    }
  }, [pass1, pass2, value, name, change]);

  return (
    <div className='text-left'>
      <PasswordInput
        change={handleChange}
        // className={`${value && value === pass2 ? 'border-green-500' : 'border-red-500'}`}
        disabled={disabled}
        helpText={helpText}
        label={'New Password'}
        name={'pass1'}
        value={pass1}
        pattern={passwordInputPattern}
        {...inputProps}
      />
      <PasswordInput
        change={handleChange}
        // className={`${value && value === pass2 ? 'border-green-500' : 'border-red-500'}`}
        disabled={disabled}
        helpText={helpText}
        label={'Confirm Password'}
        name={'pass2'}
        value={pass2}
        pattern={passwordInputPattern}
      />
        {pass1 && pass2 && pass1 !== pass2 ? <small className="text-red-500 pl-1">Passwords do not match</small> : null}
        {value && value === pass2 ? <small className="text-green-500 pl-1">Password valid</small> : null}
        {pass1 === pass2 && !strengthCheck.test(pass2) ? <small className="text-red-500 pl-1">Password does not meet requirements</small> : null}
    </div>
  )
}
NewPasswordInput.propTypes = {
  change: PropTypes.func.isRequired
  , helpText: PropTypes.any
  , name: PropTypes.string.isRequired
  , disabled: PropTypes.bool
}


NewPasswordInput.strengthCheck = strengthCheck;

export default NewPasswordInput;