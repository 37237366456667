import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../../global/utils/api';

import {
  handleCreateFulfilled
  , handleFetchSinglePending
  , handleFetchSingleFulfilled
  , handleFetchSingleRejected
  , handleFetchListPending
  , handleFetchListFulfilled
  , handleFetchListRejected
  , handleMutationPending
  , handleMutationFulfilled
  , handleMutationRejected
  , handleDeletePending
  , handleDeleteFulfilled
  , handleDeleteRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
  , handleAddSingleToList
} from '../../../global/utils/storeUtils';

// First define all API calls for user
/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 * 
 * In practice we won't dispatch these directly, they will be dispatched by userService which has a nicer api built on hooks.
 */

// CREATE
export const sendCreateUser = createAsyncThunk(
  'user/sendCreate'
  , async (newUser) => {
    const endpoint = `/api/users`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newUser);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleUser = createAsyncThunk(
  'user/fetchSingle'
  , async (id) => {
    const endpoint = `/api/users/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchUserList = createAsyncThunk(
  'user/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/users${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateUser = createAsyncThunk(
  'user/sendUpdate'
  , async ({ _id, ...updates }) => {
    const endpoint = `/api/users/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendAdminResetPassword = createAsyncThunk(
  'user/sendAdminResetPassword'
  , async (id) => {
    const endpoint = `/api/users/admin-reset-password/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'POST');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteUser = createAsyncThunk(
  'user/sendDelete'
  , async (id) => {
    const endpoint = `/api/users/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the userSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const userSlice = createSlice({
  name: 'user'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   * 
   * Again, we will not dispatch these directly, they will be dispatched by userService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addUserToList: handleAddSingleToList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   * 
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateUser.fulfilled, handleCreateFulfilled)

      // READ
      .addCase(fetchSingleUser.pending, handleFetchSinglePending)
      .addCase(fetchSingleUser.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleUser.rejected, handleFetchSingleRejected)
      .addCase(fetchUserList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchUserList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'users'))
      .addCase(fetchUserList.rejected, handleFetchListRejected)
      // UPDATE
      .addCase(sendUpdateUser.pending, handleMutationPending)
      .addCase(sendUpdateUser.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateUser.rejected, handleMutationRejected)
      .addCase(sendAdminResetPassword.pending, handleMutationPending)
      .addCase(sendAdminResetPassword.fulfilled, handleMutationFulfilled)
      .addCase(sendAdminResetPassword.rejected, handleMutationRejected)
      // .addCase(sendUpdateUser.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteUser.pending, handleDeletePending)
      .addCase(sendDeleteUser.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteUser.rejected, handleDeleteRejected)
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addUserToList } = userSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export const fetchListIfNeeded = (queryKey) => (dispatch, getState) => {
  const userQuery = getState().user.listQueries[queryKey];
  if(shouldFetch(userQuery)) {
    // console.log('Fetching user list', queryKey);
    dispatch(fetchUserList(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

export const fetchSingleIfNeeded = (id) => (dispatch, getState) => {
  const userQuery = getState().user.singleQueries[id];
  if(shouldFetch(userQuery)) {
    dispatch(fetchSingleUser(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export default userSlice.reducer;
