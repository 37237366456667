import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
  handleCreateFulfilled
  , handleFetchSinglePending
  , handleFetchSingleFulfilled
  , handleFetchSingleFromListFulfilled
  , handleFetchSingleRejected
  , handleFetchListPending
  , handleFetchListFulfilled
  , handleFetchListRejected
  , handleMutationPending
  , handleMutationFulfilled
  , handleMutationRejected
  , handleDeletePending
  , handleDeleteFulfilled
  , handleDeleteRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
  , handleAddSingleToList
  , handleAddManyToList
} from '../../global/utils/storeUtils';


// First define all API calls for assessment

// define and export the strings for the different specific assessment endpoints once here because the idea of using strings in the component gives me hives.
// we'll catch for these strings on the server side and apply the correct permissions to the query.
// these are passed in to the assessmentService hooks at the component level as the endpoint argument.
// NOTE: If any of these are functions that require arguments, return null until all arguments are provided so the fetching hook will hold off on the fetch instead of calling an endpoint that will fail.
// export const myExampleEndpoint = 'example-endpoint';
// export const myEndpointWithArgs = (arg1, arg2) => {
//   if(!arg1 || !arg2) return null;
//   return `example-endpoint/${arg1}/${arg2}`;
// };

export const adminTemplatesListEndpoint = 'admin-templates';
export const instructorPersonalTemplateListEndpoint = 'instructor-personal-templates';
export const instructorGlobalTemplateListEndpoint = 'instructor-global-templates'; 
export const assessmentsByInstructorCourseEndpoint = courseId => !courseId ? null : `by-instructor-course/${courseId}`
export const assessmentsByStudentCourseEndpoint = courseId => !courseId ? null : `by-student-course/${courseId}`

/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 * 
 * In practice we won't dispatch these directly, they will be dispatched by assessmentService which has a nicer api built on hooks.
 */

// CREATE
export const sendCreateAssessment = createAsyncThunk(
  'assessment/sendCreate'
  , async (newAssessment) => {
    const endpoint = `/api/assessments`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newAssessment);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendCreateCourseAssessment = createAsyncThunk(
  'assessment/sendCreateCourseAssessment'
  , async ({courseId, newAssessment}) => {
    const endpoint = `/api/assessments/${courseId}`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newAssessment);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendCopyAssessmentToCourse = createAsyncThunk(
  'assessment/sendCopyAssessmentToCourse'
  , async ({courseId, assessmentId}) => {
    const endpoint = `/api/assessments/${courseId}/copy/${assessmentId}`;
    const response = await apiUtils.callAPI(endpoint, 'POST', {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleAssessment = createAsyncThunk(
  'assessment/fetchSingle'
  , async (id) => {
    const endpoint = `/api/assessments/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchAssessmentList = createAsyncThunk(
  'assessment/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/assessments${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// for each resource we can add as many endpoints as we want in this format and we only need two actions to handle them.
// this will hit the same endpoint as the list version, but the store will handle the returned array and access the single item in it.
export const fetchSingleAssessmentAtEndpoint = createAsyncThunk(
  'assessment/fetchSingleWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/assessments${query}` // example: `/api/assessments/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchAssessmentListAtEndpoint = createAsyncThunk(
  'assessment/fetchListWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/assessments${query}`; // example: `/api/assessments/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateAssessment = createAsyncThunk(
  'assessment/sendUpdate'
  , async ({ _id, ...updates }) => {
    const endpoint = `/api/assessments/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteAssessment = createAsyncThunk(
  'assessment/sendDelete'
  , async (id) => {
    const endpoint = `/api/assessments/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the assessmentSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const assessmentSlice = createSlice({
  name: 'assessment'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   * 
   * Again, we will not dispatch these directly, they will be dispatched by assessmentService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addAssessmentToList: handleAddSingleToList
    , addAssessmentsToList: handleAddManyToList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   * 
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateAssessment.fulfilled, handleCreateFulfilled)
      .addCase(sendCreateCourseAssessment.fulfilled, handleCreateFulfilled)
      .addCase(sendCopyAssessmentToCourse.fulfilled, handleCreateFulfilled)

      // READ
      .addCase(fetchSingleAssessment.pending, handleFetchSinglePending)
      .addCase(fetchSingleAssessment.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleAssessment.rejected, handleFetchSingleRejected)
      .addCase(fetchAssessmentList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchAssessmentList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'assessments'))
      .addCase(fetchAssessmentList.rejected, handleFetchListRejected)

      // permission protected single fetches
      .addCase(fetchSingleAssessmentAtEndpoint.pending, handleFetchSinglePending)
      // these endpoints return named lists, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchSingleAssessmentAtEndpoint.fulfilled, (state, action) => handleFetchSingleFromListFulfilled(state, action, 'assessments'))
      .addCase(fetchSingleAssessmentAtEndpoint.rejected, handleFetchSingleRejected)
      // permission protected list fetches
      .addCase(fetchAssessmentListAtEndpoint.pending, handleFetchListPending)
      .addCase(fetchAssessmentListAtEndpoint.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'assessments'))
      .addCase(fetchAssessmentListAtEndpoint.rejected, handleFetchListRejected)

      // UPDATE
      .addCase(sendUpdateAssessment.pending, handleMutationPending)
      .addCase(sendUpdateAssessment.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateAssessment.rejected, handleMutationRejected)
      // .addCase(sendUpdateAssessment.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteAssessment.pending, handleDeletePending)
      .addCase(sendDeleteAssessment.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteAssessment.rejected, handleDeleteRejected)
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addAssessmentToList, addAssessmentsToList } = assessmentSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// accepts an optional listFetch action so we can use it for other list fetches, defaults to fetchAssessmentList
export const fetchListIfNeeded = (queryKey, listFetch = fetchAssessmentList) => (dispatch, getState) => {
  const assessmentQuery = getState().assessment.listQueries[queryKey];
  if(shouldFetch(assessmentQuery)) {
    // console.log('Fetching assessment list', queryKey);
    dispatch(listFetch(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

// accepts an optional singleFetch action so we can use it for other single fetches, defaults to fetchSingleAssessment
export const fetchSingleIfNeeded = (id, singleFetch = fetchSingleAssessment) => (dispatch, getState) => {
  const assessmentQuery = getState().assessment.singleQueries[id];
  if(shouldFetch(assessmentQuery)) {
    dispatch(singleFetch(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export default assessmentSlice.reducer;
