/**
 * View component for /user/register
 *
 * On successful registration this component forwards the user back to referrer
 * or to the root if there is no referrer.
 *
 * NOTE: upon reaching this page, user can toggle between /user/login and
 * /user/register without changing the original referring source route.
 */
// import primary libraries
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { sendRegister } from '../authStore';

// import user components
import UserLayout from '../components/UserLayout.jsx';
import UserRegisterForm from '../components/UserRegisterForm.jsx';
import { useLoggedInUser } from '../authService';
import apiUtils from '../../../global/utils/api';

const UserRegister = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const loggedInUser = useLoggedInUser();
  const [user, setUser] = useState({username: '', password: '', first_name: '', last_name: ''});
  const { redirect } = apiUtils.objectFromQueryString(location.search);
  const [errors, setErrors] = useState({email_error: '', password_error: '', first_name_error: '', last_name_error: ''});

  useEffect(() => {
    if(loggedInUser) {
      window.location.assign(redirect || "/learn")
    }
  }, [loggedInUser, redirect]);

  useEffect(() => {
    const newErrors = {...errors};
    if (user?.first_name && errors.first_name_error) {
      newErrors.first_name_error = '';
    }
    if (user?.last_name && errors.last_name_error) {
      newErrors.last_name_error = '';
    }
    setErrors(newErrors);

  }, [user])

  const handleSubmit = async e => {
    e.preventDefault();

    await handleNameErrors();

    if (user.first_name && user.last_name) {
      await registerUser();
    }
  }

  const registerUser = async () => {
    const { payload: loggedInUser, error } = await dispatch(sendRegister(user));
    // adapted from: https://reactrouter.com/web/example/auth-workflow
    const from = location?.state?.from || { pathname: "/" };

    if(loggedInUser) {
      history.replace(from.pathname, location.state);
    } else {
      if (error?.message) {
        handleThisErrors(error);
      }
    }
  }

  const handleThisErrors = (err) => {
    const newErrors = {...errors};
    if (err?.message === 'Email address already in use. Try logging in.' || err?.message === 'This user already exists. Please go to the login page and use your email.') {
      newErrors.email_error = err.message;
    } else {
      newErrors.email_error = '';
    }
    if (err?.message === 'Password strength requirements not met.') {
      newErrors.password_error = err.message;
    } else {
      newErrors.password_error = '';
    }
    setErrors(newErrors);
  }

  const handleNameErrors = () => {
    const newErrors = {...errors};
    const text = 'This field is required';
    if (!user?.first_name) {
      newErrors.first_name_error = text;
    } else {
      newErrors.first_name_error = '';
    }
    if (!user?.last_name) {
      newErrors.last_name_error = text;
    } else {
      newErrors.last_name_error = '';
    }

    setErrors(newErrors);
  }

  return (
    <UserLayout title="Register">
      <UserRegisterForm
        errors={errors}
        user={user}
        handleChange={e => setUser({...user, [e.target.name]: e.target.value})}
        handleSubmit={handleSubmit}
        redirectPath={location.search}
      />
    </UserLayout>
  )
}

export default UserRegister
